import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import {getLogger} from './logger';
import * as utils from './utils'

// TODO: Compile "Core" as separate eager module for ultra fast loads?
// TODO: Import manifest.json(s) etc for more meta information?
// import publicManifest from '../public/manifest.json';
const publicManifest = {};

const { isDev, isLocalhost } = utils;

// TODO: Better place for this/get from manifest?
const DEFAULT_CONFIG = {
  theme: {
    global: {
      colors: {
        // TODO: Liveto Dark Mode
        livetoRed: ["#e7a9a3", "#e2948d", "#dc7e76", "#d6695f", "#d05348", "#ca3e31", "#c4281a", "#b02417", "#9d2015", "#891c12"],
      },
      primaryColor: "livetoRed",
      defaultRadius: 8,

      fontFamily: "Roboto",
    },
  },
  // TODO: Use package.json or automagic based on found .ftl files
  language: 'en',
  languages: ["en", "fi", "sv"],
}

// TODO: Don't expose whole mfe for security reasons, only loader stub needed by webpack dynamic remotes
// @ts-ignore
const LivetoMFE = window.__LVT_MFE = (() => {
  // @ts-ignore
  if (window.__LVT_MFE) return window.__LVT_MFE;
  const logger = getLogger('MFE');

  const { localStorage } = window;
  const { currentScript } = document;
  // @ts-ignore
  const baseUrl = new URL('./', currentScript && currentScript.baseURI.replace(/\/$/, '') || 'http://localhost:3000');
  // @ts-ignore
  logger.log(`Bootstrap @ ${baseUrl}`);

  const manifest = Object.assign({}, {
    remotes: {},
    config: DEFAULT_CONFIG
  }, publicManifest);

  try {
    // TODO: Add dev key checking to ensure only Liveto devs can use magic
    const magic = localStorage.getItem('__LVT_DEV_MAGIC');
    if (magic) {
      if (isDev) logger.warn('MIXING RUNTIME DEV MAGIC WITH LOCAL DEV MAGIC!!!');
      const json = JSON.parse(magic)
      logger.log(`CASTING RUNTIME DEV MAGIC "${magic}"`);
      if (json.remotes) {
        if (isDev) logger.warn('You feel that local magic prevails over remotes', json.remotes);
        else logger.log('You feel your mastery over remotes take hold', manifest.remotes);
        manifest.remotes = utils.deepMerge(manifest.remotes, json.remotes);
      }
      if (json.config) {
        manifest.config = utils.deepMerge(manifest.config, json.config);
        logger.log('You feel your configration magic take effect', manifest.config);
      }
    }
  } catch (err) {
    logger.error(`CASTING DEV MAGIC FIZZLES!!!`, err);
  }

  const mf = manifest;
  const config = mf.config = utils.toNestedMap(mf.config);
  // logger.log('Manifest', mf);

  // TODO: Can we isolate MFE SW & IFrame messages to only us somehow, MessagePort maybe?
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.addEventListener("message", (event) => {
      if (!event.data || !event.data.type) return;
      logger.log(`Received SW Message`, event.data);
    });
    navigator.serviceWorker.ready.then((registration) => {
      registration.active?.postMessage({type: "PING"});
    });
    serviceWorkerRegistration.register('./sw-loader.js');
  }

  // const iframeSrc = new URL(`http://localhost:3000/Framework/iframe.html`)
  // const iframe = document.createElement('iframe');
  // iframe.src = `${iframeSrc}`;
  // // @ts-ignore
  // iframe.style = 'display: none;';
  // iframe.addEventListener('load', () => {
  //   const iwin: any = iframe.contentWindow;
  //   window.addEventListener('message', (event: any) => {
  //     if (event.source !== iwin) return;
  //     if (!event.data || !event.data.type) return;
  //     logger.log('Received IFrame Message', event.data);
  //     if (event.data.type === 'PUSH_SUBSCRIPTION') {
  //       logger.log('Push Subscription', JSON.stringify(event.data.data));
  //     }
  //   });
  //   iwin.postMessage({type: 'PING'}, iframeSrc.origin);
  //   // FIXME: For testing
  //   iwin.postMessage({type: 'GET_PUSH_SUBSCRIPTION'}, iframeSrc.origin);
  // }); 
  // document.body.appendChild(iframe);
  // logger.log('NOTIF PERM', Notification.permission);

  class LivetoMFE {
    constructor() {
      this.init();
    }

    init() {
      return Promise.resolve(this);
      /* TODO: "Lazy" init core API Clients etc
      this.api = { // Shared API Client(s)
        v2: {}, // TODO: v2 API Client (Lattice Microservice)
        v1: {}, // TODO: v1 API Client (Python)
      }

      this.sw = {}; // TODO: Service Worker

      this.cache = {}; // TODO: Shared Persistent Cache
      this.fs = {}; // TODO: Browser filesystem
      this.magic = {}; // TODO: Dev Magic Helpers?
      this.env = {}; // TODO: Runtime Env Injection?
      */
    }

    // TODO: Make persistent?
    config = {
      get: (key: string, def: any) => {
        if (key.indexOf('.') === -1) return config[key] || def;
        else return utils.getNestedKey(config, key, def)
      },
      set: (key: string, value: any) => {
        return utils.setNestedKey(config, key, value);
      }
    }

    // TODO: Per Component styles and overrides
    theme(ctx: any) {
      return Object.assign({},
        this.config.get('theme.global', {}),
        this.config.get(`theme.${ctx.MODULE_NAME}`, {})
      )
    }

    // Will be called in production by webpack to resolve remote module spec eg. Framework@master
    modprobe(spec: string) {
      const [module, version] = spec.split('@');
      const isBranch = ['master', 'develop', 'experimental'].indexOf(version) !== -1;
      const commit = isBranch ? `@${version}` : version;
      // @ts-ignore
      // If running from localhost ignore version
      // TODO: Support versions in runtime manifest in addition to absolute urls
      // FIXME: Better way to figure out correct module root url regarless of original host
      const url = mf.remotes[spec] || mf.remotes[module] || (isLocalhost ?
        `${baseUrl}../${module}/remote.js` : `https://cdnpub.liveto.io/js/mfe/${module}/${commit}/remote.js`
      );
      // logger.log(`Modprobe ${module}@${version}`, url);
      return url;
    }
  }

  return new LivetoMFE();
})();

export default LivetoMFE;
// These will probably accessed a lot so here're easy shorthands
export const mfe = LivetoMFE;
export const cfg = LivetoMFE.config;
