/* eslint-disable no-restricted-globals */
export const isDev = process.env.NODE_ENV !== 'production';
export const isLocalhost = Boolean(
  self.location.hostname === 'localhost' ||
  // host.docker.internal is host localhost from docker
  self.location.hostname === 'host.docker.internal' ||
  // [::1] is the IPv6 localhost address.
  self.location.hostname === '[::1]' ||
  // 127.0.0.0/8 are considered localhost for IPv4.
  self.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
);

export const getRootURL = (module: string, version: string) => {
  const commit = ['master', 'develop', 'experimental'].indexOf(version) !== -1 ? `@${version}` : version;
  // @ts-ignore
  console.log('getRootUrl', module, version, commit, __webpack_public_path__ || self.location.origin);
  // FIXME: Better way to figure out correct module root url regarless of original host
  if (!isLocalhost) return `https://cdnpub.liveto.io/js/mfe/${module}/${commit}/`;
  // @ts-ignore
  return new URL(isLocalhost ? `../${module}/` : `../../${module}/${commit}/`, __webpack_public_path__ || self.location.origin);
}

export const getNestedKey = (obj: any, key: string, def: any) => {
  if (key.indexOf('.') === -1) return obj[key] || def;
  else {
    const parts = key.split('.');
    const last = parts.pop();
    let n = obj;
    while (parts.length && n) {
      const ks = parts.shift();
      // @ts-ignore
      n = n[ks];
    }
    if (!n) return def;
    // @ts-ignore
    return n[last] || def;
  }
}
export const setNestedKey = (obj: any, key: string, value: any, create = false) => {
  if (key.indexOf('.') === -1) return obj[key] = value;
  else {
    const parts = key.split('.');
    const last = parts.pop();
    let n = obj;
    while (parts.length > 0 && n) {
      const ks = parts.shift();
      // @ts-ignore
      if (!n[ks]) {
        if (!create) throw new Error('setNestedKey no nested object and not creating new ones');
        // @ts-ignore
        n[ks] = {};
      }
      // @ts-ignore
      n = n[ks];
    }
    // @ts-ignore
    return n[last] = value;
  }
}
export const toFlatMap = (obj: any) => {
  const flat = {};
  for (const [key, value] of Object.entries(obj)) {
    if (typeof value === 'object' && !(value instanceof Array)) {
      for (const [sk, sv] of Object.entries(toFlatMap(value))) {
        // @ts-ignore
        flat[`${key}.${sk}`] = sv;
      }
      // @ts-ignore
    } else flat[key] = value;
  }
  return flat;
}
export const toNestedMap = (obj: any) => {
  if (typeof obj !== 'object' || (obj instanceof Array)) return obj;
  const nested = {};
  for (const [key, value] of Object.entries(obj)) {
    setNestedKey(nested, key, value, true);
  }
  return nested;
}
// Not the most efficient deep merge but gets the job done
export const deepMerge = (...objs: any[]) => {
  // @ts-ignore
  return toNestedMap(Object.assign(...objs.map(obj => toFlatMap(obj))));
}

export const devNop = (method: any) => {
  if (!isDev && !isLocalhost) return method;
  // const noopLogger = getLogger(`DEV NOP ${method.name}`);
  return (...args: any[]) => { console.debug(`NOP ${method.name}`, ...args); }
};

export const clamp = (val: any, min: any, max: any) => val > max ? max : (val < min ? min : val);

export const hsl2rgb = (h: number, s: number, l: number): number[] => {
  s /= 100;
  l /= 100;
  const k = (n: number) => (n + h / 30) % 12;
  const a = s * Math.min(l, 1 - l);
  const f = (n: number) => l - a * Math.max(-1, Math.min(k(n) - 3, Math.min(9 - k(n), 1)));
  return [255 * f(0), 255 * f(8), 255 * f(4)];
}

export const rgb2hsl = (r: number, g: number, b: number): number[] => {
  r /= 255;
  g /= 255;
  b /= 255;
  const l = Math.max(r, g, b);
  const s = l - Math.min(r, g, b);
  const h = !s ? 0 : (l === r ? ((g - b) / s) : (l === g ? 2 + (b - r) / s : 4 + (r - g) / s));
  return [
    60 * h < 0 ? 60 * h + 360 : 60 * h,
    100 * (s ? (l <= 0.5 ? s / (2 * l - s) : s / (2 - (2 * l - s))) : 0),
    (100 * (2 * l - s)) / 2,
  ];
}

export const parseRGB = (col: string): number[] => {
  if (!col.length) throw new Error(`Invalid color: ${col}`);
  if (col[0] === '#') col = col.slice(1);
  if (col.length === 3) throw new Error('Shorthand #rgb colors not supported yet');
  const num = parseInt(col, 16);
  const r = ((num >> 16) & 0xff);
  const g = ((num >> 8 ) & 0xff);
  const b = ((num      ) & 0xff);
  return [r, g, b];
}

export const stringifyRGB = (r: number, g: number, b: number) => {
  return ((r << 16) | (g << 8) | b).toString(16);
}

export const simpleHue = (col: string, offset: number) => {
  const [r, g, b] = parseRGB(col);
  let [h, s, l] = rgb2hsl(r, g, b);
  h = (h + offset) % 360
  // @ts-ignore
  const ret = stringifyRGB(...hsl2rgb(h, s, l));
  // console.log('simpleHue', col, offset, 'RET', ret, 'RGB', r, g, b, 'HSL', h, s, l);
  return ret;
}

export const simpleSaturate = (col: string, amt: number): string => {
  const [r, g, b] = parseRGB(col);
  let [h, s, l] = rgb2hsl(r, g, b);
  s += amt;
  // @ts-ignore
  const ret = stringifyRGB(...hsl2rgb(h, s, l));
  // console.log('simpleSaturate', col, amt, 'RET', ret, 'RGB', r, g, b, 'HSL', h, s, l);
  return ret;
}

export const urlBase64ToUint8Array = (base64String: string) => {
  const padding = '='.repeat((4 - base64String.length % 4) % 4);
  const base64 = (base64String + padding)
      .replace(/-/g, '+')
      .replace(/_/g, '/');

  // @ts-ignore
  const rawData = atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}