// TODO: Use existing eg. workbox logger?
import { simpleHue } from "./utils";

const CI = process.env.CI;

const BASE_COLOR = '#c4281a';

const borderBoxStyle: string[] = [
  `border-radius: 0.5em`,
  `color: white`,
  `font-weight: bold`,
  `padding: 2px 0.5em`,
];

// Keep global count of generated loggers to add unique color etc
let loggers = 0;
export const getLogger = (name: string, options: any = {}) => {
  if (CI) return console;
  options = Object.assign({
    baseColor: '#' + simpleHue(BASE_COLOR, (loggers * 66))
  }, options);

  loggers++;

  const methods = ['debug', 'log', 'warn', 'error', 'groupCollapsed', 'groupEnd'];

  let inGroup = 0;
  const logPrintFn = (method: string) => {
    const styles = [
      ...borderBoxStyle,
      `background: ${options.baseColor}`,
    ];
    const logPrefix = inGroup ? [] : [`%c${name}`, styles.join(';')];
    return (...args: any[]) => {
      if (method === 'groupCollapsed') inGroup++;
      // @ts-ignore
      console[method](...logPrefix, ...args);
      if (method === 'groupEnd') inGroup--;
    }
  };

  // Proxy console.* message functions
  const logger: any = {};
  for (const method of methods) {
    logger[method] = logPrintFn(method);
    // logger[method](`TEST ${method} MESSAGE`);
  }
  return logger;
}